import React, { useState } from 'react';
import Navbar from "../Navbar/Navbar.jsx";
import Footer from "../Footer/Footer.jsx"

import "./Tutors.css";
import { Link } from 'react-router-dom';
import tutoropportunities from "../../assets/coursedata/tutoropportunities.js"
import { useEffect } from 'react';

const Tutors = () => {

  const initialDescriptionHeight = 80; // Initial max-height for the description
  const [descriptionHeight, setDescriptionHeight] = useState(initialDescriptionHeight);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setDescriptionHeight(showFullDescription ? initialDescriptionHeight : "none");
    setShowFullDescription(!showFullDescription);
  };
  
  useEffect(() => {
    const oppoElements = document.querySelectorAll('.oppo');

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const animateIn = () => {
      oppoElements.forEach((element) => {
        if (isInViewport(element)) {
          element.classList.add('bounceIn');
        }
      });
    };

    animateIn();

    window.addEventListener('scroll', animateIn);

    return () => {
      window.removeEventListener('scroll', animateIn);
    };
  }, []);

  return (
    <div>
      <Navbar />
      
      <div className='tutorpage'>
        <div className='tutorheader'>
          <div className='tutorheaderdiv'>
            <h1 className='tutorheaderh1'>Zamonaviy innovatsion ilmlarni o'ragatadigan, <br /> O'zbekistondagi birinchi Online Ta'lim Agentligi</h1>
            <p className='tutorheaderh2'>O'qituvchi sifatida - Online dars o'tish orqali daromad toping.</p>
            <Link className='tutorheaderlink'to="/tutorariza">Ro'yhatdan o'tish</Link>
          </div>
        </div>
        
        <div className="tutorwhy">
          <p className='tutorwhyh1'>
              Nimaga uchun <span>Global Cloud Academy</span>ni tanlashiz kerak?
          </p>

          <div className="description">
            <div className="description-content-wrapper">
              <p className="description-content" style={{ maxHeight: descriptionHeight }}>
                Global Cloud Academy Online Ta'lim Agentligi O'zbekistonda virtual ta'limni  rivojlantirish maqsadida, o'z faoliyatini boshladi. O'zini karyerasini ta'lim berish orqali boshlamoqchi bo'lgan o'qituvchi tutorlarga keng qamrovli imkoniyatlarini taklif qiladi. Siz o'qituvchi sifatida, ananaviy ta'lim o'quv markazlardan farqli o'laroq Global Cloud Academy uchun emas balki u bilan birga ishlaysiz. Bunda siz qimmatli vaqtingizni sarflab ta'lim markaziga borishingiz shart emas balki o'zingizga qulay vaqtda va joyda ilmga chanqoq yoshlarimizga uyda o'tirgan holda kompyuter va internet orqali ta'lim berishingiz mumkin.
                Siz aziz o'qituvchi tutorlar Global Cloud Academy platformasi orqali faqatgina aktiv daromad emas balki passiv daromad ham topish imkoniyatiga ega bo'lasiz. 
                Biz uchun emas, Biz bilan ishlang. Siz kerak bo'lgan barcha imkoniyatlarni Global Cloud Academy o'z zimmasiga oladi.
              </p>
            </div>
            <div className="show-more-less">
              <span className="show-link" onClick={toggleDescription}>
                {showFullDescription ? "Show Less" : "Show More"}
              </span>
            </div>
          </div> 

          <div className='oppos'>
            {tutoropportunities.map((item) => (
              <div className='oppo'>
                <img src={item.image} alt="" className='oppoicon'/>
                <h3 className='opponame'>{item.name}</h3>
                <p className='oppodesc'>{item.desc}</p>
              </div>
            ))}
          </div>

          <div>
            <h4 className='kickstart'>O'qituvchi-tutorlik karyerangizni boshlamoqchimisiz?</h4>
            <Link className='hro' to="/tutorariza">Hoziroq royhatdan otish</Link>
          </div>

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Tutors