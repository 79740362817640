
import english from "../images/english.jpg";
import german from "../images/german.jpg";
import korean from "../images/korean.jpg";
import wdesign from "../images/wdesign.jpg";
import gdesign from "../images/gdesign.jpg";
import satcourse from "../images/satcourse.png";
import english2 from "../images/english2.jpg";
import german2 from "../images/german2.jpeg";
import korean2 from "../images/korean2.jpg";
import japanese from "../images/japanese.jpg";
import japanese2 from "../images/japanese2.jpg";
import arabic from "../images/arabic.jpg";
import arabic2 from "../images/arabic2.jpg";
import russian from "../images/russian.png";
import russian2 from "../images/russian2.png";
import satcourse2 from "../images/satcourse2.png";
import webdesign2 from "../images/webdesign2.jpeg";
import gdesign2 from "../images/gdesign2.jpg";
import bm from "../images/bm.jpg";
import bm2 from "../images/bm2.jpg";
import finance from "../images/finance.jpg";
import finance2 from "../images/finance2.jpg";
import economics from "../images/economics.jpg";
import economics2 from "../images/economics2.jpg";
import pm from "../images/pm.jpg";
import pm2 from "../images/pm2.jpg";
import statistics from "../images/statistics.jpg";
import statistics2 from "../images/statistics2.jpg";
import fa from "../images/fa.jpg";
import fa2 from "../images/fa2.jpg";
import sap from "../images/sap.jpg";
import sap2 from "../images/sap2.jpg";
import strategy from "../images/strategy.jpg";
import strategy2 from "../images/strategy2.jpg";
import acs1 from "../images/acs1.jpg";
import acs2 from "../images/acs2.jpg";
import ack from "../images/ack.jpg";
import marketing from "../images/marketing.jpg";
import marketing2 from "../images/marketing2.jpg";
import dataanaliz from "../images/dataanaliz1.jpg";
import dataanaliz2 from "../images/dataanaliz2.jpg";
import mobilography from "../images/mobilography1.jpeg";
import mobilography2 from "../images/mobilography12.jpeg";


const courses = [
    {
        id: "01",
        title: "Ingliz tili",
        image: english,
        image2: english2,
        a1: "Level: Beginner and Elementary",
        duration1: "5 oy",
        price1: "200000 so'm",
        package1: "900000 so'm",

        a2: "Level: Pre-Intermediate",
        duration2: "2.5 oy",
        price2: "250000so'm",
        package2: "500000 so'm",

        a3: "Level: Intermediate and Upper-intermediate",
        duration3: "5 oy",
        price3: "300000 so'm",
        package3: "1300000 so'm",

        a4: "Level: Pre-IELTS and IELTS",
        duration4: "6 oy",
        price4: "350000 so'm",
        package4: "2000000 so'm",

        a5: "Level: Pre-CEFR and CEFR",
        duration5: "6 oy",
        price5: "300000 so'm",
        package5: "1600000 so'm",

        description: `Ingliz tilini o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!

        Ingliz tili dunyo bo'ylab kommunikatsiya, biznes, ta'lim va sayohat uchun ishlatiladigan global til hisoblanadi. Siz boshlang'ich darajada bo'lsangiz yoki til bilimlarini oshirishni hohlasangiz, bizning kurslarimiz sizning uchun.
        
        Tajribali o'qituvchilarimiz sifatli ta'lim, qiziqarli darslar va amaliy mashg'ulotlar orqali sizga yordam berishadi. Interaktiv darslar orqali ingliz tilida gaplashish, o'qish va yozishni mukammal o'rganasiz.
        
        Bizning onlayn o'quv markazimizda, siz o'zingizga mos vaqtga mos bo'lib o'rganish imkoniyatiga ega bo'lasiz. Akademik maqsadlar, kasbiy rivojlanish yoki shaxsiy o'sish uchun ingliz tili kerak bo'lsa, biz siz uchun mos kurslarni taqdim etamiz.
        
        Bugun bizning ingliz tilini o'rganish kurslarimizga yoziling va bugungi dunyo bilan aloqalar qurishda muhim qadam qo'ying!
        
        Kurslar haqida ko'proq ma'lumot olish va ro'yxatdan o'tish uchun, iltimos "Kursga yozilish" tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan ingliz tilini o'rganing va yangi imkoniyatlarga ochiq yo'l toping!`,

        category: "tilkurslar",
        name: "Til Kurslari"
    },

    {
        id: "02",
        title: "Nemis Tili",
        image: german,
        image2: german2,
        a1: "Daraja: A1 va A2",
        duration1: "6 oy",
        price1: "250000 so'm",
        package1: "1400000 so'm",

        a2: "Daraja: B1",
        duration2: "3 oy",
        price2: "300000 so'm",
        package2: "800000 so'm",

        a3: "Daraja: B2 va C1",
        duration3: "6 oy",
        price3: "350000 so'm",
        package3: "2000000 so'm",

        a4: "Daraja: C2",
        duration4: "3 oy",
        price4: "400000 so'm",
        package4: "1000000 so'm",

        description: `Nemis tilini o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!

        Nemis tili dunyo bo'ylab kommunikatsiya, biznes, ta'lim va sayohat uchun ishlatiladigan global til hisoblanadi. Siz boshlang'ich darajada bo'lsangiz yoki til bilimlarini oshirishni hohlasangiz, bizning kurslarimiz sizning uchun.
        
        Tajribali o'qituvchilarimiz sifatli ta'lim, qiziqarli darslar va amaliy mashg'ulotlar orqali sizga yordam berishadi. Interaktiv darslar orqali Nemis tilida gaplashish, o'qish va yozishni mukammal o'rganasiz.
        
        Bizning onlayn o'quv markazimizda, siz o'zingizga mos vaqtga mos bo'lib o'rganish imkoniyatiga ega bo'lasiz. Akademik maqsadlar, kasbiy rivojlanish yoki shaxsiy o'sish uchun Nemis tili kerak bo'lsa, biz siz uchun mos kurslarni taqdim etamiz.
        
        Bugun bizning Nemis tilini o'rganish kurslarimizga yoziling va bugungi dunyo bilan aloqalar qurishda muhim qadam qo'ying!
        
        Kurslar haqida ko'proq ma'lumot olish va ro'yxatdan o'tish uchun, iltimos "Kursga yozilish" tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan Nemis tilini o'rganing va yangi imkoniyatlarga ochiq yo'l toping!`,
        category: "tilkurslar"
    },

    {
        id: "03",
        title: "Koreys Tili",
        image: korean,
        image2: korean2,

        a1: "Daraja: Topik 1",
        duration1: "6 oy",
        price1: "250000 so'm",
        package1: "1400000 so'm",

        a2: "Daraja: Topik 2",
        duration2: "6 oy",
        price2: "300000 so'm",
        package2: "1600000 so'm",

        description: `Koreys tilini o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!
        
        O'rganganlaringizni yanada oshirishni hohlaysizmi? Yoki birinchi marta Koreys tilini o'rganishni istaysizmi? Bizning kurslarimiz sizga mos tajribani taqdim etadi. O'quvchilarimizga qulay va samarali o'rganish imkoniyatlarini taklif etamiz. O'qituvchilarimiz Koreys tilini o'rganishni oson va qiziqarli qilishga ulgurganlar.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos darslar o'tkazish imkoniyatlari va turli o'rganish usullari mavjud. Siz Koreys tili orqali har qanday ish, o'qish, yoki sayoxat uchun tayyorlanasiz.
        
        Koreys tilini o'rganishga qiziqqan bo'lsangiz, bizning kurslarimizga yoziling va yangi imkoniyatlarga ochiq yo'l toping!
        
        Koreys tilini o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan Koreys tilini o'rganing va xorijiy imkoniyatlarning dunyasiga kirish yo'lingizni oching!`,

        category: "tilkurslar"
    },

    {
        id: "04",
        title: "Yapon Tili",
        image: japanese,
        image2: japanese2,

        a1: "Daraja: N5 va N4",
        duration1: "6 oy",
        price1: "250000 so'm",
        package1: "1400000 so'm",

        a2: "Daraja: N3 , N2, N1",
        duration2: "6 oy",
        price2: "300000 so'm",
        package2: "1600000 so'm",
        description: `Yapon tilini o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!
        
        O'rganganlaringizni yanada oshirishni hohlaysizmi? Yoki birinchi marta Yapon tilini o'rganishni istaysizmi? Bizning kurslarimiz sizga mos tajribani taqdim etadi. O'quvchilarimizga qulay va samarali o'rganish imkoniyatlarini taklif etamiz. O'qituvchilarimiz Yapon tilini o'rganishni oson va qiziqarli qilishga ulgurganlar.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos darslar o'tkazish imkoniyatlari va turli o'rganish usullari mavjud. Siz Yapon tili orqali har qanday ish, o'qish, yoki sayoxat uchun tayyorlanasiz.
        
        Yapon tilini o'rganishga qiziqqan bo'lsangiz, bizning kurslarimizga yoziling va yangi imkoniyatlarga ochiq yo'l toping!
        
        Yapon tilini o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan Yapon tilini o'rganing va xorijiy imkoniyatlarning dunyasiga kirish yo'lingizni oching!`,
        
        category: "tilkurslar"
    },

    {
        id: "05",
        title: "Arab Tili",
        image: arabic,
        image2: arabic2,
        katta: "CEFR",
        kattaprice: "500000 so'm",
        grammar: "Grammatika",
        grammarprice: "400000 so'm",
        description: `Arab Tili O'rganish uchun Onlayn O'quv Markazimizga Xush Kelibsiz!

        Arab tilini o'rganganlar va uni o'rganmoqchi bo'lganlar uchun zamonaviy til sifatida mashhur. Bizning o'quv markazimiz sizga Arab tilini oson va samarali o'rganish imkoniyatlarini taklif etadi.
        
        Bizning o'qituvchilar jamoasi sizning talablaringizni qondirish uchun maxsus dasturlar va interaktiv darslar tayyorlaydi. Siz Arab tilini boshlang'ich darajada o'rganishingiz yoki o'z bilimlarini yanada oshirishingiz kerak bo'lsa, bizning kurslarimiz sizga mos keladi.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos, interaktiv va qiziqarli darslar tayyorlanadi. Siz Arab tilida kommunikatsiya qobiliyatini oshirish va so'zlashishda ishonchli bo'lish imkoniyatiga ega bo'lasiz.
        
        Arab tilini o'rganishga qiziqish va tajribangizni yanada oshirish uchun biz bilan birgalikda Arab tilidagi kurslarimizga yoziling!
        
        Arab tilini o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan Arab tilini o'rganing va yangi imkoniyatlarga ochiq yo'l toping!
        `,
        category: "tilkurslar"
    },

    {
        id: "06",
        title: "Rus Tili",
        image: russian,
        image2: russian2,
        
        a1: "Грамматика и Разговорная речь",
        duration1: "5 oy",
        price1: "300000 so'm",
        package1: "1400000 so'm",

        description: `Rus tili tilini o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!

        Rus tili tilini o'rganganlar va uni o'rganmoqchi bo'lganlar uchun zamonaviy til sifatida mashhur. Bizning o'quv markazimiz sizga Rus tili tilini oson va samarali o'rganish imkoniyatlarini taklif etadi.
        
        Bizning o'qituvchilar jamoasi sizning talablaringizni qondirish uchun maxsus dasturlar va interaktiv darslar tayyorlaydi. Siz Rus tili tilini boshlang'ich darajada o'rganishingiz yoki o'z bilimlarini yanada oshirishingiz kerak bo'lsa, bizning kurslarimiz sizga mos keladi.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos, interaktiv va qiziqarli darslar tayyorlanadi. Siz Rus tili tilida kommunikatsiya qobiliyatini oshirish va so'zlashishda ishonchli bo'lish imkoniyatiga ega bo'lasiz.
        
        Rus tili tilini o'rganishga qiziqish va tajribangizni yanada oshirish uchun biz bilan birgalikda Rus tili tilidagi kurslarimizga yoziling!
        
        Rus tili tilini o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan Rus tili tilini o'rganing va yangi imkoniyatlarga ochiq yo'l toping!`,
        category: "tilkurslar"
    },

    {
        id: "07",
        title: "SAT Math",
        image: satcourse,
        image2: satcourse2,

        a1: "Foundation",
        duration1: "3 oy",
        price1: "500000 so'm",
        package1: "1400000 so'm",

        a2: "Main",
        duration2: "3 oy",
        price2: "600000 so'm",
        package2: "1700000 so'm",

        a3: "Senior",
        duration3: "3 oy",
        price3: "700000 so'm",
        package3: "2000000 so'm",

        description: `SAT testini tayyorlanish uchun onlayn o'quv markazimizga xush kelibsiz!

        SAT testi, AQShning talabalar uchun kiritish imkoniyatlarini baholash uchun zarur bo'lgan muhim sinovlardan biri hisoblanadi. Sizning akademik karyerangiz va o'qish yo'lingiz uchun muhim bo'lgan SAT testiga tayyorlanishga yordam beramiz.
        
        Bizning o'quv markazimiz, sizga SAT testining har bir qismi uchun o'zlashtirilgan o'rganish dasturlarini taklif etadi. Sizning imtihondagi ko'nikmalaringizni oshirish va natijalaringizni yuksaltish uchun malakangizni rivojlantirishga yordam beramiz.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos darslar o'tkazish imkoniyatlari va turli o'rganish usullari mavjud. Sizning akademik maqsadlaringiz va talabalik istaklariga mos keluvchi o'quvchilar bilan birgalikda sizni tayyorlanish jarayonida yaxshi natijalarga yo'naltiramiz.
        
        SAT testiga tayyorlanishga qiziqqan bo'lsangiz, bizning kurslarimizga yoziling va imtihonga ishonch bilan tayyorlang!
        
        SAT testiga tayyorlanish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan SAT testiga tayyorlang va o'zingizning akademik muvaffaqiyatingizga yo'l oching!`,
        category: "satkurslar",
        name: "SAT Kurslari"
    },

    {
        id: "08",
        title: "SAT English",
        image: satcourse,
        image2: satcourse2,

        a1: "Foundation",
        duration1: "3 oy",
        price1: "500000 so'm",
        package1: "1400000 so'm",

        a2: "Main",
        duration2: "3 oy",
        price2: "600000 so'm",
        package2: "1700000 so'm",

        a3: "Senior",
        duration3: "3 oy",
        price3: "700000 so'm",
        package3: "2000000 so'm",

        description: `SAT testini tayyorlanish uchun onlayn o'quv markazimizga xush kelibsiz!

        SAT testi, AQShning talabalar uchun kiritish imkoniyatlarini baholash uchun zarur bo'lgan muhim sinovlardan biri hisoblanadi. Sizning akademik karyerangiz va o'qish yo'lingiz uchun muhim bo'lgan SAT testiga tayyorlanishga yordam beramiz.
        
        Bizning o'quv markazimiz, sizga SAT testining har bir qismi uchun o'zlashtirilgan o'rganish dasturlarini taklif etadi. Sizning imtihondagi ko'nikmalaringizni oshirish va natijalaringizni yuksaltish uchun malakangizni rivojlantirishga yordam beramiz.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos darslar o'tkazish imkoniyatlari va turli o'rganish usullari mavjud. Sizning akademik maqsadlaringiz va talabalik istaklariga mos keluvchi o'quvchilar bilan birgalikda sizni tayyorlanish jarayonida yaxshi natijalarga yo'naltiramiz.
        
        SAT testiga tayyorlanishga qiziqqan bo'lsangiz, bizning kurslarimizga yoziling va imtihonga ishonch bilan tayyorlang!
        
        SAT testiga tayyorlanish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan SAT testiga tayyorlang va o'zingizning akademik muvaffaqiyatingizga yo'l oching!`,
        category: "satkurslar"
    },

    {
        id: "09",
        title: "Web Dizayn",
        image: wdesign,
        image2: webdesign2,

        a1: "Front-End Web Dasturchi",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",

        a1: "Back-End Web Dasturchi",
        duration1: "3 oy",
        price1: "1200000 so'm",
        package1: "3000000 so'm",

        description: `Veb-dizaynni o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!

        Veb-dizayn, bugungi kunda dijital sohalarda muhim va talabga ega bo'lgan mahsulot va xizmatlar taklif qilish uchun juda muhim bo'lgan mahorat hisoblanadi. Bizning o'quv markazimiz, veb-dizaynning asosiy tamoyillari va yaratuvchi texnikalarini o'rganishga yordam beradi.
        
        Bizning o'quvchilarimizga qulay va samarali o'rganish imkoniyatlarini taqdim etamiz. Veb-dizayn jarayonini qisqa vaqt ichida o'rganish uchun so'nggi texnologiyalardan foydalanamiz va amaliy mashg'ulotlar orqali ko'nikmalarini rivojlantirishga ulguramiz.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos va o'zingizga mos, interaktiv darslar tayyorlanadi. Siz veb-dizayn asoslarini o'rganish va amaliyotga o'tish jarayonida yuqori sifatli yordam va ta'minlashga ega bo'lasiz.
        
        Veb-dizayn sohasida o'z muvaffaqiyatingizni olish uchun biz bilan birgalikda veb-dizaynning eng so'nggi tendensiyalarini va metodikalarini o'rganing.
        
        Veb-dizaynni o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan veb-dizaynning eng yangi xususiyatlari va yaratuvchi potentsialiga erishib, dijital sohada o'zingizni namoyish eting!`,
        
        category: "itkurslar", 
        name: "IT Kurslari"  
    },

    {
        id: "10",
        title: "Grafik Dizayn",
        image: gdesign,
        image2: gdesign2,
        
        a1: "Grafik Dizayn",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",

        description: `Grafik dizaynni o'rganish uchun onlayn o'quv markazimizga xush kelibsiz!

        Grafik dizayn, vizual kommunikatsiya va kreativlikning kuchli vositasi hisoblanadi. Bizning o'quv markazimiz, grafik dizaynning asosiy tamoyillarini va texnikalarini o'rganishga yordam beradi.
        
        Bizning o'quvchilarimizga qulay va samarali o'rganish imkoniyatlarini taqdim etamiz. Grafik dizayn jarayonini qisqa vaqt ichida o'rganish uchun so'nggi texnologiyalardan foydalanamiz va amaliy mashg'ulotlar orqali ko'nikmalarini rivojlantirishga ulguramiz.
        
        Onlayn o'quv markazimizda, sizga zarur vaqtga mos va o'zingizga mos, interaktiv darslar tayyorlanadi. Siz kreativ fikrlashni rivojlantirish, dizaynning asosiy prinsiplarini o'rganish va kompyuter dasturlari yordamida tasviriy qobiliyatlarini rivojlantirish uchun yuqori sifatli yordam va ta'minlashga ega bo'lasiz.
        
        Grafik dizayn sohasida o'z muvaffaqiyatingizni olish uchun biz bilan birgalikda vizual san'at va kommunikatsiya usullarini o'rganing.
        
        Grafik dizaynni o'rganish uchu kursga yozilish tugmasini bosib arizani to'ldiring.
        
        Bizning onlayn o'quv markazimiz bilan kreativ fikrlashni rivojlantirib, grafik dizaynning so'nggi tendensiyalarini va texnikalarini o'rganing!`,
        category: "itkurslar"
    },

    {
        id: "11",
        title: "Business Management",
        image: bm,
        image2: bm2,

        a1: "Business Management",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",
        
        description: `Biznesni boshqarish kursida siz korxonalarda qanday qilib samarali muloqot qilish, jamoani shakillantirish va boshqa firmalar bilan hamkorlikni rivojlantirishni o'rganishingiz mumkin. Kurs davomida siz quydagilarni o’rganish imkoniyatiga ega bo’lasiz.

        -	Strategik rejalashtirish - bu aniq maqsadlarni belgilash, amalga oshirish mumkin bo'lgan rejalar yaratish va umumiy qarashlar sari sa'y-harakatlarni muvofiqlashtirishni o'z ichiga olgan muhim omildir. 
        
        -	Korxona tuzilmalar va strukturasini tushunish - sizga ish oqimlarini optimallashtirish va samarali operatsiyalarni ta'minlash imkonini beradi. 
        
        -	Inson resurslarini boshqarish – sizga xodimlarni qanday yollash, o'qitish va rag'batlantirishni o'rgatadi
        
        -	Marketing va sotish – esa maqsadli auditoriyaga erishish va daromadni oshirish strategiyalarini qamrab oladi. 
        
        -	Moliyaviy menejment hisobotlarni tahlil qilish, asoslangan moliyaviy qarorlar qabul qilish va risklarni kamaytirishni o'z ichiga oladi. 
        
        -	Bundan tashqari, ta'minot zanjiri boshqaruvini o'rganish – sizga  mahsulotni yetkazib berish va sotuvchilar o'rtasidagi munosabatlarning nozik tomonlarini tushunishga yordam beradi. 
        
        -	Va nihoyat, biznesni boshqarish axloq qoidalarini, ma'lumotlarga asoslangan qarorlarni qabul qilishni va global biznes masalalarini o'z ichiga oladi, bu sizni murakkab va dinamik biznes muhitida harakatlanishga tayyorlaydi.
        `,
        category: "businesskurslar",
        name: "Business Kurslari"
    },

    {
        id: "12",
        title: "Business Strategy",
        image: strategy,
        image2: strategy2,

        a1: "Business Strategy",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",
        
        description: `Biznes strategiyasida siz tashkilotning maqsadlarini uning harakatlariga moslashtiradigan keng qamrovli rejani qanday shakllantirishni o'rganishingiz mumkin. Bu imkoniyatlar va tahdidlarni aniqlash uchun bozor tendentsiyalarini, raqobat kuchlarini, ichki kuchli va zaif tomonlarni tahlil qilishni o'z ichiga oladi. Bozorda ajralib turish uchun aniq va tabaqalashtirilgan qiymat taklifini yaratish juda muhimdir. Biznes strategiyasi, shuningdek, qaysi bozorlarga kirish, qanday raqobat qilish va resurslarni qanday samarali taqsimlash haqida tanlashni o'z ichiga oladi.

        Siz turli xil strategik yondashuvlarni o'rganasiz, masalan, xarajatlar bo'yicha etakchilik (sanoatdagi eng past xarajatlarga erishish), farqlash (noyob va qimmatli mahsulotlarni taklif qilish) va diqqatni (muayyan bozor segmentiga qaratish). Strategik amalga oshirishni o'rganish juda muhim, chunki u yuqori darajadagi rejalarni amalga oshirish mumkin bo'lgan qadamlarga aylantirish, mas'uliyatlarni belgilash va monitoring tizimlarini o'rnatishni o'z ichiga oladi.
        
        Xatarlarni boshqarish va favqulodda vaziyatlarni rejalashtirishni tushunish biznes strategiyasining asosiy jihati hisoblanadi, chunki u kutilmagan qiyinchiliklarga tayyorlanishga yordam beradi. Axloqiy fikrlar va barqarorlik ham ajralmas hisoblanadi, chunki yaxshi ishlab chiqilgan strategiya jamiyat va atrof-muhitga ta'sirini hisobga oladi.
        
        Umuman olganda, biznes strategiyasi sizni murakkab biznes landshaftlarida harakat qilish, ongli qarorlar qabul qilish va uzoq muddatli muvaffaqiyat uchun buzines ko'nikmalarini shakillantiradi.
        `,
        category: "businesskurslar",
    },

    {
        id: "13",
        title: "Finance",
        image: finance,
        image2: finance2,

        a1: "Finance",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",
        
        description: `In the domain of finance, you can acquire knowledge about effectively managing money, investments, and financial resources. This includes understanding financial statements to assess an entity's fiscal health and performance. You'll learn budgeting and financial planning techniques to allocate resources and achieve financial objectives. Investment strategies, such as stocks, bonds, and real estate, will be explored to help you make informed choices aligned with risk and return preferences. The concept of time value of money, encompassing present and future value calculations, aids in evaluating investment profitability. Additionally, insights into financial markets, trading, and ethical considerations provide a well-rounded understanding of financial decision-making and its impact.

        Moliya sohasida siz pul, investitsiyalar va moliyaviy resurslarni samarali boshqarish bo'yicha bilimlarga ega bo'lishingiz mumkin. Bunga korxonaning fiskal salomatligi va faoliyatini baholash uchun moliyaviy hisobotlarni tushunish kiradi. Siz resurslarni taqsimlash va moliyaviy maqsadlarga erishish uchun byudjet va moliyaviy rejalashtirish usullarini o'rganasiz. Aktsiyalar, obligatsiyalar va ko'chmas mulk kabi investitsiya strategiyalari risk va daromadlilik imtiyozlari bilan uyg'unlashgan holda qaror qabul qilishga yordam berish uchun o'rganiladi. Hozirgi va kelajakdagi qiymat hisoblarini o'z ichiga olgan pulning vaqt qiymati tushunchasi investitsiyalarning rentabelligini baholashda yordam beradi. Bundan tashqari, moliyaviy bozorlar, savdo va axloqiy mulohazalar haqidagi tushunchalar moliyaviy qarorlarni qabul qilish va uning ta'sirini har tomonlama tushunish imkonini beradi.
        `,
        category: "businesskurslar",
    },

    {
        id: "14",
        title: "Economics",
        image: economics,
        image2: economics2,

        a1: "Economics",
        duration1: "3 oy",
        price1: "1000000 so'm",
        package1: "2500000 so'm",
        
        description: `Iqtisodiyotni o'rganish jamiyatdagi resurslarni qanday taqsimlash va boshqarishni tushunish uchun eshiklarni ochadi. Siz bozor o'zaro ta'siri va narxlarni asoslaydigan talab va taklif kabi tushunchalarni o'rganasiz. Mikroiqtisodiyot individual xatti-harakatlarga, bozor tuzilmalariga va resurslarni taqsimlashga qaratilgan bo'lsa, makroiqtisodiyot milliy yoki global miqyosda iqtisodiy o'sish, inflyatsiya va ishsizlik kabi kengroq masalalarni o'rganadi. Iqtisodiy ko'rsatkichlar va ularning oqibatlarini o'rganish sizni iqtisodiy tendentsiyalarni sharhlash va asoslangan qarorlar qabul qilish imkonini beradi. Siz moliya va pul-kredit siyosati kabi davlat siyosatining iqtisodiyotga ta'sir qilishdagi roli haqida tushunchaga ega bo'lasiz. Bundan tashqari, xalqaro savdo va globallashuvni tushunish iqtisodlarning bir-biri bilan qanday bog'langanligini va savdo boylik taqsimoti va iqtisodiy rivojlanishga qanday ta'sir qilishi mumkinligini tushunishga yordam beradi.`,
        category: "businesskurslar",
    },

    {
        id: "15",
        title: "Statistic Analysis",
        image: statistics,
        image2: statistics2,

        a1: "Statistic Analysis",
        duration1: "3 oy",
        price1: "1500000 so'm",
        package1: "4000000 so'm",
        
        description: `Statistik tahlil sohasida siz mazmunli xulosalar chiqarish uchun ma'lumotlarni to'plash, tartibga solish va sharhlash usullarini o'zlashtirishingiz mumkin. Ta'riflovchi statistika sizga o'rtacha, median va standart og'ish kabi ko'rsatkichlar yordamida ma'lumotlarni umumlashtirish va taqdim etish imkonini beradi. Inferentsial statistika gipoteza sinovi va ishonch oralig'i kabi tushunchalar bilan namunaviy ma'lumotlar asosida populyatsiyalar haqida bashorat qilish va umumlashtirishga yordam beradi. Ehtimollar nazariyasini o'rganish ma'lumotlardagi noaniqlik va tasodifiylikni tushunish uchun asos yaratadi. Regressiya tahlili o'zgaruvchilar o'rtasidagi munosabatlarni modellashtirish va ushbu munosabatlar asosida bashorat qilish imkonini beradi. Vaqt seriyalari tahlili sizni vaqt o'tishi bilan ma'lumotlar nuqtalarini tahlil qilish, va tendentsiyalarni aniqlash imkonini beradi.

        Bundan tashqari, siz diagrammalar, grafiklar va chizmalar orqali tushunchalarni samarali etkazish uchun ma'lumotlarni vizualizatsiya qilish bo'yicha malakaga ega bo'lasiz. Ushbu mahorat to'plami biznes va ijtimoiy fanlardan tortib sog'liqni saqlash va tadqiqotlargacha bo'lgan turli sohalarda juda muhimdir, chunki u sizga asosli qarorlar qabul qilish va dalillarga asoslangan tavsiyalar berish imkonini beradi.
        `,
        category: "businesskurslar",
    },

    {
        id: "16",
        title: "Project Management",
        image: pm,
        image2: pm2,

        a1: "Project Management",
        duration1: "3 oy",
        price1: "1500000 so'm",
        package1: "4000000 so'm",
        
        description: `Loyihani boshqarish sohasida siz loyihalarni boshidan oxirigacha rejalashtirish, amalga oshirish va nazorat qilish ko'nikmalariga ega bo'lasiz. Siz loyiha maqsadlari, ko'lami va natijalarini aniqlashni o'rganasiz, shuningdek, vazifalarni, vaqt jadvallarini va resurslarni taqsimlashni ko'rsatadigan batafsil loyiha rejalarini yaratishni o'rganasiz. Gantt jadvallari yoki tanqidiy yo'l tahlili kabi loyihani rejalashtirish usullarini tushunish sizga vaqtni samarali boshqarish va qiyinchiliklarni aniqlash imkonini beradi. Xatarlarni boshqarish sizga potentsial to'siqlarni qanday aniqlashni, ularning ta'sirini baholashni va kamaytirish strategiyalarini ishlab chiqishni o'rgatadi. Muloqot ko'nikmalari jamoalar, manfaatdor tomonlarni muvofiqlashtirish va barchani loyihaning borishi haqida xabardor qilish uchun juda muhimdir. Tajriba orttirganingizda, siz o'zgarishlarga moslashish, muammolarni hal qilish va loyihalarning yo'lda va byudjet doirasida bo'lishini ta'minlashni ham o'rganasiz.`,
        category: "businesskurslar",
    },

    {
        id: "17",
        title: "Fundamental Business Analyse",
        image: fa,
        image2: fa2,

        a1: "Business Analyse",
        duration1: "3 oy",
        price1: "1500000 so'm",
        package1: "4000000 so'm",
        
        description: `Biznesni tahlil qilish sohasida siz murakkab biznes muammolarini tahlil qilishni, ma'lumotlardan qimmatli fikrlarni olishni va takomillashtirish strategiyalarini shakllantirishni o'rganishingiz mumkin. Siz talablarni to'plash, manfaatdor tomonlarning ehtiyojlarini tushunish va bu ehtiyojlarni aniq loyiha maqsadlariga aylantirish bo'yicha ko'nikmalarni rivojlantirasiz. Ma'lumotlarni tahlil qilish usullari sizga dalillarga asoslangan qarorlar qabul qilishda yordam beradigan ma'lumotlar to'plamidagi tendentsiyalar va korrelyatsiyalarni aniqlashga imkon beradi. Jarayonni modellashtirish sizga mavjud ish oqimlarini xaritalash va optimallashtirish sohalarini aniqlash imkonini beradi.

        Bundan tashqari, siz dasturiy ta'minot tizimlari, operatsion jarayonlar yoki tashkiliy o'zgarishlar, biznes talablarini aniqlash va hujjatlashtirish bo'yicha malakaga ega bo'lasiz. Manfaatdor tomonlarni boshqarish va muloqot qilish ko'nikmalari turli jamoalar o'rtasidagi hamkorlikni osonlashtirish, umumiy maqsadlarga mos kelishini ta'minlash uchun juda muhimdir. Biznesni tahlil qilish orqali siz samaradorlikni oshirishga, xarajatlarni kamaytirishga va tashkilotda innovatsiyalarni rivojlantirishga erishasiz. 
        `,
        category: "businesskurslar",
    },

    {
        id: "18",
        title: "SAP",
        image: sap,
        image2: sap2,

        a1: "SAP",
        duration1: "3 oy",
        price1: "1500000 so'm",
        package1: "4000000 so'm",
        
        description: `Ma'lumotlarni qayta ishlashda tizimlar, ilovalar va mahsulotlar degan ma'noni anglatuvchi SAP turli sohalarda keng ko'lamli o'rganish imkoniyatlarini taklif etadi. SAP korporativ resurslarni rejalashtirish (ERP) dasturini o'zlashtirib, biznes operatsiyalarini qanday samarali boshqarishni o'rganishingiz mumkin. Bunga Moliya (FI), Sotish va tarqatish (SD), Materiallarni boshqarish (MM) va Inson kapitalini boshqarish (HCM) kabi modullar kiradi, bu sizga moliyaviy hisob, inventarni boshqarish va ishchi kuchini boshqarish kabi jarayonlarni optimallashtirish imkonini beradi.
        
        Bundan tashqari, SAP strategik qarorlar qabul qilish uchun ma'lumotlarni tahlil qilish va vizualizatsiya qilishni o'rganishingiz mumkin bo'lgan SAP Business Intelligence (BI) kabi maxsus treklarni taklif etadi. Bundan tashqari, SAPning Mijozlar bilan munosabatlarni boshqarish (CRM) bo‘yicha treningi sizga qanday qilib mijozlarni jalb qilishni va savdo jarayonlarini soddalashtirishni o‘rgatishi mumkin.
        
        Bundan tashqari, siz logistika, xaridlar va ishlab chiqarishni rejalashtirish bo'yicha tushunchalarga ega bo'lgan SAP ta'minot zanjiri boshqaruvi yechimlari bilan tanishishingiz mumkin. SAP shuningdek, SAP HANA, xotiradagi ma'lumotlar bazasi platformasi va HANA asosida qurilgan ilg'or ERP to'plami SAP S/4HANA kabi ilg'or texnologiyalar bo'yicha treninglar beradi.
        
        Nihoyat, siz SAP’ning innovatsiyalar va biznesdagi raqamli transformatsiyani rag‘batlantirish uchun SAP yechimlariga qanday integratsiyalanganligini tushunish uchun narsalar interneti (IoT), sun’iy intellekt (AI) va mashinani o‘rganish kabi sohalarini o‘rganishingiz mumkin.
        `,
        category: "businesskurslar",
    },

    {
        id: "19",
        title: "Level - Applied Knowledge",
        image: ack,
        image2: ack,

        a1: "F1 - Business And Technology",
        duration1: "6 oy",
        price1: "1000000 so'm",
        package1: "5500000 so'm",
        
        a2: "F2 - Management Accounting",
        duration2: "6 oy",
        price2: "1000000 so'm",
        package2: "5500000 so'm",

        a3: "F3 - Financial Accounting",
        duration3: "6 oy",
        price3: "1000000 so'm",
        package3: "5500000 so'm",

        description: `ACCA ning amaliy bilimlar darajasida siz asosiy buxgalteriya hisobi va moliya tamoyillari haqida fundamental bilim va tushunchaga ega bo'lasiz. Bu daraja uchta hujjatni o'z ichiga oladi: Biznesda buxgalter (AB), boshqaruv hisobi (MA) va moliyaviy buxgalteriya (FA).
        
        Birinchidan, "Biznesda buxgalter" maqolasida siz buxgalterlarning tashkilotlardagi o'rni, biznes etikasi, boshqaruv, moliyaviy va moliyaviy bo'lmagan omillar qaror qabul qilishga qanday ta'sir qilishini bilib olasiz.
        
        Ikkinchidan, "Boshqaruv hisobi" hujjati byudjetlashtirish, xarajatlarni hisoblash texnikasi va samaradorlikni boshqarishga qaratilgan. Siz boshqaruv qarorlarini qabul qilishga yordam berish uchun moliyaviy ma'lumotlarni tahlil qilish va sharhlash ko'nikmalarini rivojlantirasiz.
        
        Uchinchidan, “Moliyaviy buxgalteriya hisobi” hujjati moliyaviy hisobotlarni tayyorlash va talqin qilishni, shu jumladan moliyaviy hisobotni tartibga soluvchi asosiy tushunchalar, qoidalar va standartlarni qamrab oladi.
        
        Umuman olganda, Amaliy bilimlar darajasi sizni buxgalteriya hisobi va moliya sohasida mustahkam asos bilan ta'minlaydi, biznes operatsiyalari, xarajatlarni hisoblash usullari va moliyaviy hisobot amaliyotlari haqida tushuncha beradi. Ushbu ko'nikmalar buxgalteriya hisobi, moliya yoki biznesni boshqarish bo'yicha martaba izlamoqchi bo'lgan har bir kishi uchun zarurdir.
        `,
        category: "accakurslar",
        name: "ACCA Kurslari"
    },

    {
        id: "20",
        title: "Level - Applied Skills",
        image: acs2,
        image2: acs2,

        a1: "F4 - Cooperate and Business Law",
        duration1: "6 oy",
        price1: "1500000 so'm",
        package1: "8000000 so'm",
        
        a2: "F5 - Performance Management",
        duration2: "6 oy",
        price2: "1500000 so'm",
        package2: "8000000 so'm",

        a3: "F6 - Taxation",
        duration3: "6 oy",
        price3: "1500000 so'm",
        package3: "8000000 so'm",

        a4: "F7 - Financial Reporting",
        duration4: "6 oy",
        price4: "1500000 so'm",
        package4: "8000000 so'm",

        a5: "F8 - Audit and Assurance",
        duration5: "6 oy",
        price5: "1500000 so'm",
        package5: "8000000 so'm",

        a6: "F9 - Financial Management",
        duration6: "6 oy",
        price6: "1500000 so'm",
        package6: "8000000 so'm",

        description: `ACCAning Amaliy ko'nikmalar darajasi Amaliy bilimlar darajasida olingan asosiy bilimlarga asoslanadi va buxgalteriya hisobi va moliyaning turli sohalari bo'yicha yanada ilg'or va amaliy tushunchalarni beradi. Bu daraja oltita hujjatdan iborat: Korporativ va biznes huquqi (LW), samaradorlikni boshqarish (PM), soliqqa tortish (TX), moliyaviy hisobot (FR), audit va kafolat (AA) va moliyaviy menejment (FM).

        "Korporativ va tadbirkorlik huquqi" maqolasida siz biznes operatsiyalarini tartibga soluvchi huquqiy asoslarni o'rganasiz va kompaniya huquqi, shartnoma qonuni va tadbirkorlik faoliyati bilan bog'liq boshqa me'yoriy hujjatlar bilan tanishasiz.
        
        "Ishlash samaradorligini boshqarish" ilg'or xarajatlarni boshqarish usullari, byudjetlashtirish va samaradorlikni o'lchashga qaratilgan. Siz biznes faoliyatini tahlil qilish va optimallashtirish ko'nikmalariga ega bo'lasiz.
        
        "Soliq solish" hujjati soliqqa tortish tamoyillari, amaliyotlari va qoidalarini qamrab oladi, bu sizni jismoniy shaxslar va korxonalar uchun soliqqa oid masalalarni tushunish va boshqarishga tayyorlaydi.
        
        “Moliyaviy hisobot” xalqaro moliyaviy hisobot standartlarini (IFRS) chuqur o‘rganib, murakkab moliyaviy hisobotlarni tayyorlash qobiliyatingizni oshirib, Amaliy bilimlar darajasida kiritilgan tushunchalarni kengaytiradi.
        
        "Audit va ishonch" sizga audit tartib-qoidalari, axloqiy mulohazalar va moliyaviy ma'lumotlarning to'g'riligi va muvofiqligini baholashda auditorlarning roli haqida o'rgatadi.
        
        "Moliyaviy menejment" moliyaviy qarorlar qabul qilish, kapitalni byudjetlashtirish, risklarni boshqarish va aylanma mablag'larni boshqarishni o'rganadi, bu sizga oqilona moliyaviy tanlov qilish ko'nikmalarini beradi.
        
        Amaliy ko'nikmalar darajasida siz buxgalteriya hisobi tamoyillari, moliyaviy qoidalar va boshqaruv amaliyotlari haqida to'liq tushunchaga ega bo'lasiz. Bu ko'nikmalar buxgalteriya hisobi, audit, soliqqa tortish, moliyaviy tahlil va menejment bo'yicha konsalting sohasida rol izlayotgan mutaxassislar uchun juda muhimdir.
        `,
        category: "accakurslar",
    },

    {
        id: "21",
        title: "Level - Strategic Professional",
        image: acs1,
        image2: acs1,

        a1: "P1 - Strategic Business Leadership",
        duration1: "6 oy",
        price1: "2000000 so'm",
        package1: "10000000 so'm",
        
        a2: "P2 - Strategic Business Reporting",
        duration2: "6 oy",
        price2: "2000000 so'm",
        package2: "10000000 so'm",

        description: `ACCA ning strategik professional darajasi buxgalteriya hisobi, moliya va biznesning ilg‘or va strategik jihatlariga e’tibor qaratuvchi ACCA malakasining cho‘qqisi hisoblanadi. Bu daraja ikkita moduldan iborat: Essentials va Options.

        "Essentials" moduli ikkita levelni o'z ichiga oladi. “Strategik biznes yetakchisi” (SBL) biznes strategiyasi, boshqaruv, risklarni boshqarish va axloqiy jihatlar haqida yaxlit tushuncha beradi. Siz strategik qarorlar qabul qilishda turli biznes intizomlarini birlashtirishni o'rganasiz.
        
        "Biznesning strategik hisoboti" (SBR) moliyaviy hisobot bilimlariga asoslanadi va murakkab hisobot masalalarini, shu jumladan moliyaviy vositalar va guruh hisobini o'rganadi. Ushbu daraja moliyaviy ma'lumotlarni samarali tahlil qilish va etkazish qobiliyatiga urg'u beradi.
        
        "Tanlovlar" moduli to'rtta variantdan ikkita darajani tanlashni taklif qiladi. "Advanced Financial Management" (AFM) ilg'or moliyaviy boshqaruv usullarini, jumladan businessni qo'shib olish va sotib olish, investitsiyalarni baholash va risklarni boshqarishni o'rgatadi.
        
        "Advanced Performance Management" (APM) ish faoliyatini baholash, strategik rejalashtirish va qarorlarni qo'llab-quvvatlash uchun ilg'or boshqaruv hisobi usullariga qaratilgan.
        
        "Advanced Taxation" (ATX) murakkab soliq qoidalarini, jumladan, shaxsiy va korporativ soliqqa tortish, xalqaro soliq masalalari va soliqni rejalashtirish strategiyalarini qamrab oladi.
        
        "Advanced Audit and Assurance” (AAA) sizni audit va maslahat xizmatlaridagi rollarga tayyorlab, murakkab audit protseduralari, professional etika va ishonchni ta'minlash bo'yicha majburiyatlarni o'rgatadi.
        
        Strategik professional darajada siz strategik qarorlar qabul qilish, moliyaviy hisobotning nozik jihatlari va moliya, soliqqa tortish va auditning ixtisoslashgan sohalari haqida chuqur tushunchaga ega bo'lasiz. Ushbu ko'nikmalar moliyaviy direktor, katta auditor yoki boshqaruv maslahatchisi kabi yuqori lavozimlar uchun juda muhimdir, chunki ular biznes dinamikasini chuqur tushunish va muhim strategik tanlovlarni amalga oshirish qobiliyatini talab qiladi.
        `,
        category: "accakurslar",
    },

    {
        id: "22",
        title: "Marketing",
        image: marketing,
        image2: marketing2,

        a1: "Marketing",
        duration1: "3 oy",
        price1: "1500000 so'm",
        package1: "4000000 so'm",

        description: `Marketingda siz mahsulotlar, xizmatlar yoki g'oyalarni maqsadli auditoriyaga samarali targ'ib qilish tamoyillari va strategiyalarini o'rganishingiz mumkin. Bu kampaniyalarni moslashtirish uchun iste'molchilarning xulq-atvorini tushunish, tendentsiyalar va raqobatchilarni aniqlash uchun bozor tadqiqotlarini o'tkazish va aniq bozor mavjudligini yaratish uchun brending va joylashishni aniqlash strategiyalarini ishlab chiqishni o'z ichiga oladi. Bundan tashqari, siz turli xil kanallar orqali mijozlarga erishish va ularni jalb qilish uchun reklama, jamoatchilik bilan aloqalar va savdoni rag'batlantirish kabi turli xil reklama usullarini o'rganasiz. Umuman olganda, marketing ta'limi sizni jozibali xabarlar tayyorlash, bozor dinamikasini tahlil qilish va biznes muvaffaqiyatiga hissa qo'shadigan strategik qarorlar qabul qilish ko'nikmalari bilan ta'minlaydi.`,
        category: "digitalmarketing",
        name: "Digital Marketing"
    },

    {
        id: "23",
        title: "Mobilografiya",
        image: mobilography,
        image2: mobilography2,

        a1: "Mobilografiya",
        duration1: "2 oy",
        price1: "800000 so'm",
        package1: "1500000 so'm",

        description: `Mobilografiyada siz mobil qurilmangiz yordamida jozibali va ko'zga ko'rinadigan fotosuratlarni olish san'atini o'rganishingiz mumkin. Bu estetik jihatdan yoqimli tasvirlarni yaratish uchun zarur bo'lgan kompozitsiya, yorug'lik va ramka kabi fotografiya asoslarini tushunishni o'z ichiga oladi. Shuningdek, siz smartfon kameralarining imkoniyatlarini o‘rganasiz, optimal ekspozitsiya, fokus va maydon chuqurligi uchun sozlamalarni qanday sozlashni o‘rganasiz. Bundan tashqari, siz to'g'ridan-to'g'ri mobil qurilmangizdan professional ko'rinishdagi tasvirlarni yaratishga imkon beruvchi fotosuratlaringizni yaxshilash uchun turli xil mobil fotosuratlarni tahrirlash texnikasi va ilovalarini topasiz.`,
        category: "digitalmarketing",
    },

    {
        id: "25",
        title: "Data Analiz",
        image: dataanaliz,
        image2: dataanaliz2,

        a1: "Data Analiz",
        duration1: "3 oy",
        price1: "2000000 so'm",
        package1: "5000000 so'm",

        description: `Ma'lumotlarni tahlil qilishda siz katta va murakkab ma'lumotlar to'plamini tushunish uchun ko'nikma va texnikani o'rganishingiz mumkin. Bu tahlil qilish uchun ma'lumotlarni qanday tozalash, tartibga solish va qayta ishlashni tushunishni o'z ichiga oladi. Shuningdek, siz ma'lumotlar ichida yashiringan naqshlar, tendentsiyalar va tushunchalarni ochishga imkon beruvchi turli statistik usullar va vositalarni o'rganasiz. Bundan tashqari, siz biznes va moliyadan tortib sog'liqni saqlash va ilm-fangacha bo'lgan sohalarda ongli qarorlar qabul qilishni qo'llab-quvvatlash uchun topilmalaringizni qanday qilib vizualizatsiya qilish va samarali etkazishni o'rganasiz. Umuman olganda, ma'lumotlarni tahlil qilish sizni xom ma'lumotlarni mazmunli natijalarga olib keladigan amaliy tushunchalarga aylantirish qobiliyati bilan ta'minlaydi.`,
        category: "digitalmarketing",
    },
];

export default courses;