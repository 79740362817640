import React from 'react'
import { Link } from 'react-router-dom'

const SuccessPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <div style={{ fontSize: '100px', color: '#4caf50' }}>&#10004;</div>
      <div style={{ fontSize: '36px', marginBottom: '20px', color: '#333' }}>Ariza muvofaqqiyatli topshirildi !</div>
      <div style={{ fontSize: '18px', color: '#666' }}></div>
      <div style={{ fontSize: '16px', color: '#999', marginTop: '30px', fontSize: "17px", color: "red" }}>
        Qo'shimcha eslatmalar:
        <ul style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '0', listStyle: 'none', fontSize: "17px", color: "red"}}>
          <li>Biz siz bilan 24 soat ichida bog'lanamiz.</li>
          <li>Kuningiz xayrli o'tsin.</li>
        </ul>
      </div>
      <div style={{ marginTop: '30px' }}>
        <Link to="/" style={{ fontSize: '18px', color: '#007bff', textDecoration: 'none' }}>Bosh sahifaga qaytish</Link>
      </div>
    </div>
  )
}

export default SuccessPage