
import efficient from "../images/efficent.png";
import effective from "../images/effective.webp"
import easy from "../images/easy.png";



const ourvalues = [
    {
        image: efficient,
        name: "Samarali",       
    },

    {
        image: effective,
        name: "Sifatli",       
    },

    {
        image: easy,
        name: "Oson",       
    }
]

export default ourvalues;