import React from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import coursedata from '../../../assets/coursedata/coursedata';
import { Link, useParams } from 'react-router-dom';
import "./FilterCourse.css";

const FilterCourse = () => {
  const { category } = useParams();

  const filteredCourses = coursedata.filter((course) => course.category === category);

  return (
    <>
      <Navbar />
      <div className='filteredcoursePage'>
        <div className='filteredcoursepage'>
          <h2 className='filteredcoursetitle'>{[filteredCourses[0].name]}</h2>
          <div className='dashwhite'></div>
          <div className='filteredcourse'>
            {filteredCourses.length > 0 ? (
              <div className='filtercard'>
                {filteredCourses.map((course) => (
                  <div key={course.id} className='filtercoursediv'>
                    <img src={course.image} alt="" className='filtercourseimg'/>
                    <h2 className='courseh2'>
                      {/* Use the Link component to create a link to the course page */}
                      {course.title}
                    </h2>
                    <Link to={`/kurslar/${course.id}`} className='infobutton'>Info</Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>Course not found.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FilterCourse;