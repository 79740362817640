import React from 'react';
import emailjs from 'emailjs-com';
import "./TutorApplication.css";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailConfig from './emailconfig/emailconfig';
import applogo from "../../../assets/images/applogo.png"

const TutorApplication = () => {

    const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    dob: '',
    gender: '',
    course: '',
    experience: '',
    recommendation: '',
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.send(
      emailConfig.serviceID,
      emailConfig.templateID,
      {
        to_name: 'Global Cloud Academy', // Replace with the recipient's name
        from_name: formData.name,
        reply_to: formData.email,
        phone: formData.phone,
        dob: formData.dob,
        gender: formData.gender,
        experience: formData.experience,
        course: formData.course,
        recommendation: formData.recommendation,
      },
      emailConfig.userID
    )
    .then((response) => {
      console.log('Email sent successfully!', response);

      navigate('/ariza/topshirildi');
    })
    .catch((error) => {
      console.error('Error sending the email:', error);
    });
  };


  return (
    <div className='applicationpage'>
      <form onSubmit={handleSubmit} className='form'>
        <img src={applogo} alt="" className='applogo'/>
        <div className='input'>
          <label>To'liq ismi sharf *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder='M: Sherali Jabborov'
            required
          />
        </div>  
        
        <div className='input'>
          <label>Telefon raqam *</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder='M: +998901112233'
            required
          />
        </div>

        <div className='input'>
          <label>Email manzil (ihtiyoriy)</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder='M: emailmanzil@gmail.com'
          />
        </div>

        <div className='input'>
          <label>Kursning nomi *</label>
          <input
            name="course"
            value={formData.course}
            onChange={handleChange}
            placeholder='M: Grafik Dizayn'
            required
          />
        </div>

        <div className='input'>
          <label>Tajriba *</label>
          <input
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            placeholder='M: 2 yil'
            required
          />
        </div>

        <div className='input'>
          <label>Tug'ilgan sana *</label>
          <input
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            placeholder='M: 20 avgust, 1995 yil'
            required
          />
        </div>

        <div className='input'>
          <label>Jins: </label>
          <input
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            placeholder='M: Ayol'
            required
          />
        </div>

        <div className='input'>
          <label>O'quv markaz haqida qayerdan eshitdiz ?</label>
          <input
            type='text'
            name="recommendation"
            value={formData.recommendation}
            onChange={handleChange}
            placeholder='M: Instgramda'
          />
        </div>
        
        <button type="submit">Tasdiqlash</button>
      </form>
    </div>
  )
}

export default TutorApplication