
import meeting from "../../assets/images/meeting.svg";
import lesson from "../../assets/images/lesson.svg";
import result from "../../assets/images/result.svg";
import confidense from "../../assets/images/confidense.svg";
import flexible from "../../assets/images/flexible.svg";
import homework from "../../assets/images/homework.svg";

const studentopportunities = [
    {
        image: meeting,
        name: "Shaxsiy qo'llab-quvvatlash",
        desc: "Malakali o'qituvchi-tutorlardan ta'lim oling"
    },

    {
        image: lesson,
        name: "Maxsus Darslar",
        desc: "Kelajagingiz uchun poydevor quring"
    },

    {
        image: result,
        name: "Yuqori Natijalar",
        desc: "Istaganizdanda balandroq natijalarga erishing"
    },

    {
        image: confidense,
        name: "Iqtidoringizni Oshiring",
        desc: "O'zingizga bo'lgan ishonchni ilm orqali yuksaltiring"
    },

    {
        image: flexible,
        name: "Qulay",
        desc: "Istalgan vaqtda va joyda ilm oling"
    },

    {
        image: homework,
        name: "Ishonchli va Sifatli",
        desc: "Sifatli ta'limdan bexabar qolib ketmang"
    },
]

export default studentopportunities;