import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaInstagram, FaTelegram, FaLinkedin } from 'react-icons/fa';
import { MdFormatListBulleted } from 'react-icons/md';
import { FaThumbsUp } from 'react-icons/fa';
import { HiOutlineHand } from 'react-icons/hi';

import "./Home.css";
import Navbar from "../Navbar/Navbar.jsx";
import Footer from '../Footer/Footer';
import aboutimg from "../../assets/images/aboutimg.png";
import categorydata from "../../assets/coursedata/categorydata";
import comforts from '../../assets/coursedata/comfortabilities';

const Home = () => {

  return (
    <div className='homepage'>
      <Navbar />
      <div className='firstpart'>
        <div className='headtxtdiv'>  
          <h3 className='h4cca'>Online O'quv Markaz</h3>
          <h2 className='h2cca'>Istalgan joyda va Istalgan Vaqtda</h2>
          <h1 className='h1cca'>TEZ, OSON va SIFATLI Ta'lim</h1>
        </div>
      </div>

      <div className='comfort'>
        {comforts.map((item) => (
          <div className='comfortcard'>
            <img src={item.image} alt="" className='comforticon'/>
            <h3 className='comfortname'>{item.name}</h3>
          </div>
        ))}
      </div>

      <div className='coursesection'>
        <div className='coursesheading'>
          <h1>Kurslar</h1>
        </div>

        <div className='second'>
          {categorydata.map((category) => (
            <Link to={`/${category.category}`} className='course'>
              <img src={category.image} alt="" className='courseicon'/>
              <h3 className='coursetxt'>{category.name}</h3>
            </Link>
          ))}
        </div>
      </div>

      <div className='showway'>
        <div className='qadamheader'>
          <h4>Uchta bosqich bilan kurslarga yoziling</h4>
        </div>
        <div className='qadam'>
          <div className='qadamdiv'>
            <div className='qadamtext'>1-Bosqich</div>
            <div className='qadamicon'><MdFormatListBulleted className='qicon'/></div>
            <div className='qadamway'><p className='qadamwaytxt'>Kurslar sahifasiga o'ting</p></div>
          </div>
          <div  className='qadamdiv'>
            <div className='qadamtext'>2-Bosqich</div>
            <div className='qadamicon'><FaThumbsUp className='qicon'/></div>
            <div className='qadamway'><p className='qadamwaytxt'>Yoqtirgan kursingiz ustiga bosing</p></div>
          </div>
          <div className='qadamdiv'>
            <div className='qadamtext'>3-Bosqich</div>
            <div className='qadamicon'><HiOutlineHand className='qicon'/></div>
            <div className='qadamway'><p className='qadamwaytxt'>Kursga yozilish tugmasini bosing</p></div>
          </div>
        </div>
      </div>
      
      <div className='abt'>
        <div className='aboutheading'>
          <h1>Ma'lumot</h1>
        </div>
        <div className='third'>
          <div className='aboutimgdiv'>
            <img src={aboutimg} alt="" className='aboutimg'/>
          </div>
          <div className='abouttxt'>
              <span className='abtxt'>
                Assalomu aleykum!
                Qadrli ustozlar va ilm talabidagi yoshlar, sizlarga Global Claud Akademiyasi haqida ma’lumot bermoqchiman.
                Global Claud Academiyasi - Onlayn platforma bo’lib - Internet orqali ta'lim olish va ta’lim berishni osonlashtiradigan raqamli muhitdir. 
                Bu platforma o‘qituvchilarga kurs materiallarini yaratib, masofadan turli multimedia vositalari orqali talabalar bilan muloqot qilish, o’qitish va ularning bilim-ko’nikmalarini masofaviy baholash imkonini beradi.
                Ushbu platforma oʻquvchilarga online taʼlim olish eshiklarini ochadi.
                Platformaning yana bir qulayligi, Oʻqituvchilar istalgan joy va vaqtda ilm ulashibgina qolmay, balki activ va passiv daromad olish imkoniga ega bo'lishadi. 
                Masofaviy taʼlim imkoniyatlaridan foydalanib o'z maqsadlaringizga Global Claud Akademiyasi bilan birgalikda erishing.
              </span>
              <Link to="/ma'lumot"><h3 className='buttontxt'>Davom etish...</h3></Link>
          </div>
        </div>
      </div>

      <div className='soclinks'>
        <h1>Ijtimoiy Tarmoqlar</h1>
      </div>

      <div className='fourth'>
        <div className='socicons'>
          <div className='socicon'>
            <Link className='soclink' to="https://www.instagram.com/globalcloudacademy/" target='_blank'><FaInstagram className='icon' /></Link>
          </div>
          <div className='socicon'>
            <Link className='soclink' to="https://t.me/globalcloudacademy" target='_blank'><FaTelegram className='icon'/></Link>
          </div>
          <div className='socicon'>
            <Link className='soclink' to="https://www.linkedin.com/in/cloudcampus-academy-9b0471285/" target='_blank'><FaLinkedin className='icon' /></Link>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>

  )
}

export default Home