import React from 'react';

import "./Courses.css"
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import coursesdata from "../../assets/coursedata/coursedata";
import { Link } from 'react-router-dom';

const Courses = () => {
  return (
    <>
      <Navbar />

      <div className='coursePage'>
        <div className='coursepage'>
          <h2 className='heading'>Kurslar</h2>
          
          <div className='dashwhite'></div>

          <div className='coursecard'>
            {coursesdata.map((course) => (
              <div key={course.id} className='coursediv'>
                <img src={course.image} alt="" className='courseimg'/>
                <h2 className='courseh2'>
                  {/* Use the Link component to create a link to the course page */}
                  {course.title}
                </h2>
                <Link to={`/kurslar/${course.id}`} className='infobutton'>Info</Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Courses