import React, { useEffect } from 'react';
import {  HashRouter,  Routes,  Route,  useLocation,} from "react-router-dom";

import Home from "./components/Home/Home";
import Courses from "./components/Courses/Courses";
import About from "./components/About/About";
import CourseDetailPage from './components/Courses/SingleCourses/CourseDetailPage';
import Contact from "./components/Contact/Contact";
import FilterCourse from './components/Courses/FilterCourse/FilterCourse';
import Application from './components/Application/Application';
import SuccessPage from './components/Application/successpage/SuccessPage';
import Tutors from './components/Tutors/Tutors';
import Students from './components/Students/Students';
import Team from './components/Team/Team';
import TutorApplication from './components/Tutors/TutorApplication/TutorApplication';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <HashRouter scrollRestoration="auto">
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kurslar" element={<Courses />} />
          <Route path="/ma'lumot" element={<About />} />
          <Route path="/tutorlar" element={<Tutors />} />
          <Route path="/students" element={<Students />} />
          <Route path="/ourteam" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/ariza' element={<Application />} />
          <Route path='/tutorariza' element={<TutorApplication />} />
          <Route path="/kurslar/:id" element={<CourseDetailPage />} />
          <Route path="/:category" element={<FilterCourse />} />
          <Route path='/ariza/topshirildi' element={<SuccessPage />} />
        </Routes>
      </HashRouter>
    </>
  )
}

export default App