import accaicon from "../images/accaicon.jpg";
import lanicon from "../images/lanicon.png";
import saticon from "../images/saticon.png";
import iticon from "../images/iticon.png";
import businessicon from "../images/businessicon.jpg";
import digitalmarketingicon from "../images/digitalmarketingicon.jpg"

const categorydata = [
    {
        name: 'Til Kurslari',
        image: lanicon,
        category: 'tilkurslar'
    },

    {
        name: 'SAT Kurslari',
        image: saticon,
        category: 'satkurslar'
    },

    {
        name: 'IT Kurslari',
        image: iticon,
        category: 'itkurslar'
    },

    {
        name: "Business Kurslar",
        image: businessicon,
        category:"businesskurslar"
    },

    {
        name: "ACCA",
        image: accaicon,
        category: "accakurslar"
    },

    {
        name: "Digital Marketing",
        image: digitalmarketingicon,
        category: "digitalmarketing"
    }
]

export default categorydata;