import React from 'react';
import "./CourseDetailPage.css";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import coursedata from "../../../assets/coursedata/coursedata";
import { Link, useParams } from 'react-router-dom';
import { FaClock, FaMoneyBill } from 'react-icons/fa';

const CourseDetailPage = () => {
  const { id } = useParams();
  const selectedCourse = coursedata.find((course) => course.id === id);

  return (
    <>
      <Navbar />
      <div className='coursedetailpage'>
        {selectedCourse ? (
          <div>
            <h2 className='cdtitle'>{selectedCourse.title}</h2>
            <div className='dash'></div>
            <div className='coursedetail'>
              <div className='firstdesc'>
                <img src={selectedCourse.image2} alt="" className='cdimg' />
                <div className='cdesc'>
                  <div className='hordash'></div>
                  {selectedCourse.a1 && (
                    <div>
                      <h4>{selectedCourse.a1}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Oylik to'lov: {selectedCourse.price1}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration1}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package1}</p>
                      </div>
                    </div>
                  )}

                  {selectedCourse.a2 && (
                    <div>
                      <div className='verdash'></div>
                      <h4>{selectedCourse.a2}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>O'ylik to'lov: {selectedCourse.price2}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration2}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package2}</p>
                      </div>
                    </div>
                  )}

                  {selectedCourse.a3 && (
                    <div>
                      <div className='verdash'></div>
                      <h4>{selectedCourse.a3}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>O'ylik to'lov: {selectedCourse.price3}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration3}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package3}</p>
                      </div>
                    </div>
                  )}

                  {selectedCourse.a4 && (
                    <div>
                      <div className='verdash'></div>
                      <h4>{selectedCourse.a4}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>O'ylik to'lov: {selectedCourse.price4}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration4}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package4}</p>
                      </div>
                    </div>
                  )}

                  {selectedCourse.a5 && (
                    <div>
                      <div className='verdash'></div>
                      <h4>{selectedCourse.a5}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>O'ylik to'lov: {selectedCourse.price5}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration5}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package5}</p>
                      </div>
                    </div>
                  )}

                  {selectedCourse.a6 && (
                    <div>
                      <div className='verdash'></div>
                      <h4>{selectedCourse.a6}</h4>
                      <div style={{ display: 'flex' }}>
                        <FaMoneyBill style={{ marginTop: '13px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>O'ylik to'lov: {selectedCourse.price6}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Davomiyligi: {selectedCourse.duration6}</p>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <FaClock style={{ marginTop: '12px' }} />
                        <p style={{ marginLeft: '20px', fontSize: '15px' }}>Package: {selectedCourse.package6}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className='button'>
                  <Link className='buttonlink' to="/ariza">Kursga yozilish</Link>
                </div>
              </div>
              <div className='cdescription'>
                <h3>Tavsif:</h3>
                <p style={{ whiteSpace: 'pre-line' }}>{selectedCourse.description}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>Course not found.</p>
        )}
      </div>
      <Footer />
    </>
  )
}

export default CourseDetailPage;
