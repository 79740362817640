import React, { useState } from 'react';
import { Link } from "react-router-dom";

import whatsApp from "../../assets/images/whatsApp.svg";
import email from "../../assets/images/email.svg";
import phone from "../../assets/images/phone.svg";

import logo from "../../assets/images/logo.png";
import './Navbar.css';

const Navbar = () => {

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className='navbarcontainer'>
      <div className='topnav'>
        <div className='topnavcontainer'>
        <div className='infocontainer'>
          <img src={phone} className='topnavicon' />
          <p className='topnavcontact'> +998940784262</p>
        </div>
        <div className='infocontainer'>
          <img src={email} className='topnavicon' />
          <p className='topnavcontact'>globalcloudacademy2023@gmail.com</p>
        </div>
        </div>
      </div>

      <div className='navbar'>
          <div className='logo-container'>
              <Link to="/" className='logo-container'>
                  <img src={logo} alt="" className='logoimg'/>
                  <span className='logotxt'>Global Cloud Academy</span>
              </Link>
          </div>

          <div className='link-container'>
            <div className='link-div'><Link className='links' to="/ma'lumot">Faoliyat</Link></div>
            <div className='link-div'><Link className='links' to="/kurslar">Kurslar</Link></div>
            <div className='link-div'><Link className='links' to="/tutorlar">Tutorlar</Link></div>
            <div className='link-div'><Link className='links' to="/students">O'quvchilar</Link></div>
            <div className='link-div'><Link className='links' to="/ourteam">Jamoa</Link></div>
          </div>

          <div className='contactus-container'>
            <Link to='/contact' className='contactus'>
              Murojat qilish
            </Link>
          </div>

          <div className='menu'>
            <div className="menu-icon" onClick={toggleMenu}>
              &#9776;
            </div>
            <ul className={`menu-items ${showMenu ? 'show' : ''}`}>
              <li className="menu-item"><Link className='menulink' to="/">Bosh sahifa</Link></li>
              <li className="menu-item"><Link className='menulink' to="/ma'lumot">Faoliyat</Link></li>
              <li className="menu-item"><Link className='menulink' to="/kurslar">Kurslar</Link></li>
              <li className="menu-item"><Link className='menulink' to="/tutorlar">Tutorlar</Link></li>
              <li className="menu-item"><Link className='menulink' to="/students">O'quvchilar</Link></li>
              <li className="menu-item"><Link className='menulink' to="/contact">Murojat qilish</Link></li>
            </ul>
          </div>
      </div>
    </div>
  )
}

export default Navbar