import React from 'react';

import "./Footer.css";
import { Link } from 'react-router-dom';
import logowhite from "../../assets/images/logowhite.png";
import { FaInstagram, FaLinkedin, FaMailBulk, FaPhone, FaTelegram } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='seconddiv'>
        <img src={logowhite} alt="" className='footerlogo'/>
      </div>
      <div className='firstdiv'>
        <ul className='ul'>
          <Link to='/' className='fisrtdivlink'><li className='li'>Bosh sahifa</li></Link>
          <Link to="/ma'lumot" className='fisrtdivlink'><li className='li'>Faoliyat</li></Link>
          <Link to="/kurslar" className='fisrtdivlink'><li className='li'>Kurslar</li></Link>
          <Link to='/contact' className='fisrtdivlink'><li className='li'>Murojaat qilish</li></Link>
        </ul>
      </div>
      <div className='thirddiv'>
          <div className='thirdiv2'>
            <Link className='thirddivlink' to="https://www.instagram.com/globalcloudacademy/" target='_blank'><FaInstagram className='footericon' /> @globalcloudacademy</Link>
          </div>
          <div className='thirdiv2'>
            <Link className='thirddivlink' to="https://t.me/globalcloudacademy" target='_blank'><FaTelegram className='footericon'/> @globalcloudacademy</Link>
          </div>
          <div className='thirdiv2'>
            <Link className='thirddivlink'><FaPhone className='footericon' /> +998940784262</Link>
          </div>
          <div className='thirdiv2'>
            <Link className='thirddivlink' to="mailto:contact@example.com" target='_blank'><FaMailBulk className='footericon' /> globalcloudacademy2023@gmail.com</Link>
          </div>
      </div>
    </div>
  )
}

export default Footer