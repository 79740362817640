import React from 'react';

import "./Students.css";
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import studentopportunities from '../../assets/coursedata/studentopportunities';
import studentlearn1 from "../../assets/images/studentlearn1.jpg"

const Students = () => {
  return (
    <div>
      <Navbar />
      
      <div className='studentpage'>
        <div className='studentheader'>

        </div>

        <div className="studentwhy">
          <p className='studentwhyh1'>
              Nimaga uchun <span>Global Cloud Academy</span>da o'qishingiz kerak?
          </p>

          <div className="description">
            <p className='description-content'>
              Global Cloud Academy onlayn ta'lim uchun eng maqbul tanlov bo'lib, zamon talabidagi bilim ko'nikmalar malakali mutaxassislari tomonidan olib boriladi. Siz, Global Cloud Academy yordamida eng so'ngi tipdagi ilmlar bilan qurollanib,  dunyo talabidagi raqobatbardosh  kadrga aylanasiz. Platformaning interaktiv kurslari sizga istalgan joyda ta'lim olish imkoniyatini beradi. Siz, Global Cloud Academy tamomlab, tamomlanganlik to'grisida Sertificatga ega bo'lasiz va bu sizning karyera imkoniyatlaringizni oshirishga yordam beradi. Dunyoni Global Cloud Academy bilan kashf eting
            </p>
          </div>
        </div>

        <div className='studentoppos'>
          {studentopportunities.map((item) => (
            <div className='studentoppo'>
              <img src={item.image} alt="" className='studentoppoicon'/>
              <h3 className='studentopponame'>{item.name}</h3>
              <p className='studentoppodesc'>{item.desc}</p>
            </div>
          ))}
        </div>
        
        <div className='hrodiv'>
          <h4 className='kickstart'>Shaxsiy rivojlanish uchun investitsiya kiriting</h4>
          <Link className='hro' to="/kurslar">Hoziroq boshlash</Link>
        </div>
      </div>

    <Footer />
    </div>
  )
}

export default Students