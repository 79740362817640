import flexiblee from "../../assets/images/flexiblee.svg";
import tutor from "../../assets/images/tutor.svg";
import affordable from "../../assets/images/affordable.svg";
import interactivelearing from "../../assets/images/interactivelearning.svg";
import supportservices from "../../assets/images/supportiveservice.svg";
import webinars from "../../assets/images/webinars.svg";

const comforts = [
    {
        image: tutor,
        name: "Malakali O'qituvchilar"
    },

    {
        image: interactivelearing,
        name: "Interaktiv Ta'lim"
    },

    {
        image: webinars,
        name: "Vebinarlar"
    },

    {
        image: affordable,
        name: "Xamyonbop"
    },

    {
        image: flexiblee,
        name: "Qulay"
    },

    {
        image: supportservices,
        name: "Qo'llab-quvvatlash Xizmatlari"
    }
]

export default comforts;