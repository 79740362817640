import React from 'react';

import "./Team.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import TeamMember from './TeamMember/TeamMember';
import md from "../../assets/images/md.jpg";
import ceo from "../../assets/images/ceo.jpg";
import coo from "../../assets/images/coo.jpg";

const teamMembers = [
  {
    name: 'Kalandarov Dilovar',
    role: 'CEO',
    image: ceo,
  },

  {
    name: 'Abdurazzoqov Muhammadyusuf',
    role: 'Boshqaruvchi Direktor',
    image: md,
  },

  {
    name: 'Dehqonov Rahmiddin',
    role: 'COO',
    image: coo,
  },
];

const Team = () => {
  return (
    <div>
      <Navbar />

      <div className='team-page'>
        <h1>Bizning Jamoa</h1>
        <div className='team-members'>
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Team