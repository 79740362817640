import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import contactConfig from "./contactconfig/contactConfig.js";

import Navbar from "../Navbar/Navbar";
import "./Contact.css";
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaTelegram, FaYoutube } from 'react-icons/fa';
import Footer from '../Footer/Footer.jsx';
import phonesidebar from "../../assets/images/phone-sidebar.svg";
import whatsappsidebar from "../../assets/images/whatsapp-sidebar.svg";
import envelopesidebar from "../../assets/images/envelope-sidebar.svg"
import clocksidebar from "../../assets/images/clock-sidebar.svg";
import socialsidebar from "../../assets/images/social-sidebar.svg"

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  }); 

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    emailjs.send(
      contactConfig.serviceID,
      contactConfig.templateID,
      {
        to_name: 'Recipient Name', // Replace with the recipient's name
        from_name: formData.name,
        message: formData.message,
        phone: formData.phone,
        reply_to: formData.email,
      },
      contactConfig.userID
    )
    .then((response) => {
      console.log('Email sent successfully!', response);
      setSubmissionSuccess(true); // Set success state to true
      setFormData({ // Reset the form fields
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    })
    .catch((error) => {
      console.error('Error sending the email:', error);
    });
  };


  return (
    <div>
      <Navbar />

      <div className='contactheader'>
      </div>

      <div className='contactcontainer'>
        <div className="leftcolumn">
          <h3 className='leftcolumnheader'>Quyidagi ilovani to'ldiring va biz sizga qanday yordam ko'rsata olishimizni yozib qoldiring.</h3>
          <div className='contactform'>
            <form onSubmit={handleSubmit} className='formcontact'>
              <div>
                <label>Ismingiz *</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Ismingiz"
                  className='contactinput'
                  required
                />
              </div>

              <div>
                <label>Telefon raqam*</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Telefon raqam"
                  className='contactinput'
                  required
                /> 
              </div>

              <div>
                <label>Email Manzilingiz (ihtiyoriy)</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email manzilingiz"
                  className='contactinput'
                />
              </div>

              <div>
                <label>Murojatingiz *</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Savol"
                  className='textarea'
                  required
                />
              </div>
              {submissionSuccess && <p className="success-message">Murojatingiz muvafaqiyatli jo'natildi.</p>}
              <button type="submit">Yuborish</button>
            </form>
          </div>
        </div>

        <div className="rightcolumn">
          <div className='contactinfo'>
            <div className='contactinfodiv'>
              <div className='phone'>
                <img src={phonesidebar} alt=""  className='contactphoneicon'/>
                <p className='phonetitle'>Telefon Raqam</p>
              </div>
              <div>
                <p className='phonenumber'>+998940784262</p>
              </div>
            </div>

            <div className='contactinfodiv'>
              <div className='phone'>
                <img src={whatsappsidebar} alt=""  className='contactphoneicon'/>
                <p className='phonetitle'>Whatsapp</p>
              </div>
              <div>
                <p className='phonenumber'>+998940784262</p>
              </div>
            </div>

            <div className='contactinfodiv'>
              <div className='phone'>
                <img src={envelopesidebar} alt=""  className='contactphoneicon'/>
                <p className='phonetitle'>Email Manzil</p>
              </div>
              <div>
                <p className='phonenumber'>globalcloudacademy2023@gmail.com</p>
              </div>
            </div>

            <div className='contactinfodiv'>
              <div className='phone'>
                <img src={clocksidebar} alt=""  className='contactphoneicon'/>
                <p className='phonetitle'>Ish Vaqti</p>
              </div>
              <div>
                <p className='phonenumber'>Dushanba-Shanba <br /> 09:00 - 21:00</p>
              </div>
            </div>

            <div className='contactinfodiv'>
              <div className='phone'>
                <img src={socialsidebar} alt=""  className='contactphoneicon'/>
                <p className='phonetitle'>Ijtimoiy Tarmoqlar</p>
              </div>
              <div>
                <Link className='contactsoclink' to="https://www.instagram.com/globalcloudacademy/" target='_blank'><FaInstagram className='contactsocicon' /></Link>
                <Link className='contactsoclink' to="https://t.me/globalcloudacademy" target='_blank'><FaTelegram className='contactsocicon'/></Link>
                <Link className='contactsoclink' to="https://www.youtube.com/@GlobalCloudAcademy" target='_blank'><FaYoutube className='contactsocicon' /></Link>
                <Link className='contactsoclink' to="mailto:contact@example.com" target='_blank'><FaLinkedin className='contactsocicon' /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Contact