
import received from "../images/received.svg";
import flexible from "../images/flexible.svg";
import friendly from "../images/friendly.svg";
import itsreward from "../images/itsreward.svg";
import startinstantly from "../images/start-instantly.svg";
import getpaid from "../images/getpaid.svg";

const tutoropportunities = [
    {
        image: received,
        name: "Ko'plab ishlarga ega bo'ling",
        desc: "Vatan ravnaqiga hissa qo'shing"
    },

    {
        image: getpaid,
        name: "Yaxshi daromad toping",
        desc: "Moliyaviy erkinlikka erishing"
    },

    {
        image: flexible,
        name: "Qulay dars jadvali",
        desc: "Istalgan vaqt va joyda ta'lim bering"
    },

    {
        image: startinstantly,
        name: "Zudlik bilan boshlang",
        desc: "Ilm ravnaqiga kamarbasta bo'ling"
    },

    {
        image: friendly,
        name: "Qo'llab-quvvatlash xizmati",
        desc: "Ishonchli va tezkor yordam"
    },

    {
        image: itsreward,
        name: "Mukofotlarga ega bo'ling",
        desc: "Mashaqqatingiz halovatini toping"
    }
]

export default tutoropportunities;