import React from 'react';

import "./About.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import logo from "../../assets/images/logo.png"
import ourvalues from '../../assets/coursedata/ourvalues';

const About = () => {
  return (
    <div className='aboutpage'>
      <Navbar />
      
      <div className='aboutheader'>
        <div className='aboutheaderdiv'>
          <img src={logo} alt="" className='aboutheaderlogo'/>
          <h2 className='aboutheadertitle'>Global Cloud Academy</h2>
        </div>
      </div>
      
      <div className='aboutdescdiv'>
        <p className='aboutdescp'>
          "Global Cloud Akademya" online ta'lim agentligi - ta'lim sohasidagi eng sara tajribali tutorlar xizmatidan foydalanib,  Siz aziz bilimga chanqoq o'quvchi-talabalarga Online ta'limni taklif qiladi.
        </p>          
        <p className='aboutdescp'>
          "Ta'limda yangilik - Kelajagingizni Global Cloud Akademiya bilan quring”. Global Cloud Akademiya sizga ta’limni istalgan vaqtda, istalgan joyda olish imkonini taqdim etadi. Imkoniyatni qo’ldan boy bermang - hoziroq ro’yxatdan o’ting.
        </p>
      </div>

      <div className='aboutpagedash'></div>

      <div className='ourvalues'>
        <h2 className='ourvaluestitle'>Bizning Qadriyatlarimiz</h2>

        <div className='values'>
          {ourvalues.map((item, index) => (
            <div className='value' key={index} style={{ '--delay': index }}>
              <img src={item.image} alt="" className='valueicon'/>
              <h3 className='valuename'>{item.name}</h3>
            </div>
          ))}
        </div>

        <div className='ourvaluesdesc'>
          <p className='ourvaluesdescp'>
            Bizning shior "Unlocking Potential, Virtually". Bu shuni anglatadiki Global Cloud Academy karyerasini ilm ulashishdan boshlamoqchi bo'lgan o'qituvchi-tutorlarga va zamonaviy bilimlarni o'rganmoqchi bo'lgan bilimga chanqoq o'quvchilarga o'z maqsadlariga online tarzda yetishlariga ko'prik vazifani o'taydi. Global Cloud Academy zamonaviy innovatsion jahon tajribasida sinalgan metodlardan foydalangan holda o'qituvchi-tutorlar va o'quvchilarga teng, samarali va sifatli imkoniyatlar yaratadi.
          </p>
        </div>
      </div>

      <div className='aboutpagedash'></div>

      <div className='ourvision'>
        <h2 className="ourvisiontitle">Bizning Provard Maqsadimiz</h2>

        <div className="ourvisiondesc">
          <p className="ourvisiondescp">
            Bizning maqsadimiz ananaviy ta'limga imkoniyati cheklangan ilmga chanqoq yoshlarni -  Tez, Oson, Sifatli  talim berib, nafaqat O'zbekistonda balki Markaziy Osiyoda yetakchi ta'lim agentligi bo'lishdir.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About