import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import emailConfig from './emailconfig/emailConfig.js';
import "./Application.css";
import applogo from "../../assets/images/applogo.png";
import { useNavigate } from 'react-router-dom';

const Application = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    course: '',
    recommendation: '',
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.send(
      emailConfig.serviceID,
      emailConfig.templateID,
      {
        to_name: 'CloudCampus Academy', // Replace with the recipient's name
        from_name: formData.name,
        course: formData.course,
        phone: formData.phone,
        recommendation: formData.recommendation,
        reply_to: formData.email,
      },
      emailConfig.userID
    )
    .then((response) => {
      console.log('Email sent successfully!', response);

      navigate('/ariza/topshirildi');
    })
    .catch((error) => {
      console.error('Error sending the email:', error);
    });
  };

  return (
    <div className='applicationpage'>
      <form onSubmit={handleSubmit} className='form'>
        <img src={applogo} alt="" className='applogo'/>
        <div className='input'>
          <label>To'liq ismi familyangiz *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder='M: Sherali Jabborov'
            required
          />
        </div>        
        
        <div className='input'>
          <label>Telegram username *</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder='M: @MuhammadGCA'
            required
          />
        </div>

        <div className='input'>
          <label>Email manzilingiz (ihtiyoriy)</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder='M: emailmanzil@gmail.com'
          />
        </div>

        <div className='input'>
          <label>Kursning nomi *</label>
          <input
            name="course"
            value={formData.course}
            onChange={handleChange}
            placeholder='M: Grafik Dizayn'
            required
          />
        </div>

        <div className='input'>
          <label>O'quv markaz haqida qayerdan eshitdiz ?</label>
          <input
            type='text'
            name="recommendation"
            value={formData.recommendation}
            onChange={handleChange}
            placeholder='M: Instgramda'
          />
        </div>
        
        <button type="submit">Tasdiqlash</button>
      </form>
    </div>
  );
};

export default Application;
